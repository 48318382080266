import React from 'react';

const ScootaIframeTest = () => {
    return (
        <div>
            <iframe
                src={`https://rig.scoota.com/campaigns/1073745686/adunits/1073765516/preview`}
                className={`w-full h-screen`}
            ></iframe>
        </div>
    );
};

export default ScootaIframeTest;
